.liquidationTwoDashboardLayout {
    display: flex;
    justify-content: flex-start;
    margin-top: 45px;
    margin-bottom: 45px;
}

.liquidationTwoDashboardLayout .dashboardLt {
    position: -webkit-sticky;
    position: sticky;
    top: 10px;
    max-width: 280px;
    width: 100%;
    margin-right: 25px;
}

.liquidationTwoDashboardLayout .dashboardLt ul {
    background: #f5f5f5;
    margin: 0;
    border-radius: 5px;
}

.nowrap {
    white-space: nowrap;
}

.liquidationTwo.wizardTheme .dashboardLt .MuiListItem-root {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
}

.liquidationTwo.wizardTheme .dashboardLt .MuiCollapse-root .MuiListItem-root {
    padding: 0;
}

.liquidationTwo.wizardTheme .dashboardLt .MuiList-root a {
    padding: 20px 80px !important;
    width: 100%;
}

.liquidationTwo.wizardTheme .dashboardLt .MuiList-root a.menu-section {
    padding: 20px 40px !important;
    width: 100%;
}

.liquidationTwo.wizardTheme .dashboardLt .MuiListItem-root:not(:has(a)) {
    padding: 20px 40px;
}

.liquidationTwo.wizardTheme .multisellerDashboardLayout .dashboardLt ul {
    background-color: var(--primColor);
    border-radius: 30px;
    overflow: hidden;
}

.liquidationTwo.wizardTheme .multisellerDashboardLayout .dashboardLt .MuiListItem-root a {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
}

.liquidationTwo.wizardTheme
    .multisellerDashboardLayout
    .dashboardLt
    .MuiListItem-root
    a:not(.active):hover {
    background: var(--primColor);
    color: #fff;
    opacity: 0.75;
}

.liquidationTwo.wizardTheme .multisellerDashboardLayout .dashboardRt .schedulePickupBtn {
    margin: 0 !important;
}

.liquidationTwo.wizardTheme .multisellerDashboardLayout .dashboardRt .pdtNmClmn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}

.liquidationTwo.wizardTheme .multisellerDashboardLayout .table tbody td:last-child button {
    margin: 0;
}

.liquidationTwo.wizardTheme .multisellerDashboardLayout .dashboardRt .schedulePickupBtn button {
    background-color: var(--primColor);
    border: inherit;
    border-radius: inherit;
    color: #fff;
}

.liquidationTwo.wizardTheme .liquidationDashTabs .MuiTabs-flexContainer {
    justify-content: start;
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    padding: 15px;
    box-shadow: none;
    gap: 15px;
}

.liquidationTwo.wizardTheme .dashboardRt .MuiTabs-root {
    justify-content: center;
}

.liquidationTwo.wizardTheme .liquidationDashTabs .MuiTabs-fixed {
    width: auto;
}

.liquidationTwo.wizardTheme .liquidationDashTabs .MuiTab-root {
    background-color: #fff;
    width: fit-content;
    min-width: min-content;
    border-radius: 12px;
    min-height: 38px;
}

.liquidationTwo.wizardTheme table {
    border: 1px solid #d9d9d9;
    border-radius: 12px;
    box-shadow: none;
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;
}

.liquidationTwo.wizardTheme table thead th {
    background: #fff;
    color: #000;
}

.liquidationTwo.wizardTheme table .primButton button {
    background-color: var(--secColor);
    border: 1px solid var(--secColor);
    border-radius: 12px;
}

.liquidationTwo.wizardTheme .liquidationDashTabs .MuiTab-wrapper {
    padding: 0 8px;
}

.liquidationTwo.wizardTheme .liquidationDashTabs .MuiTab-root.Mui-selected {
    background-color: var(--primColor);
}

.liquidationTwo.wizardTheme .liquidationDashTabs .MuiTabs-scroller {
    flex: none;
}

.liquidationTwo.wizardTheme .multisellerDashboardLayout .dashboardLt ul > .MuiListItem-root a span {
    color: var(--primColor);
}

.liquidationTwo.wizardTheme .multisellerDashboardLayout .dashboardLt .MuiListItem-root a.active {
    color: #fff;
    font-size: 18px;
    font-weight: 700;
}

.liquidationTwo.wizardTheme
    .multisellerDashboardLayout
    .dashboardRt
    .nav-standard-top-area-search
    .MuiTextField-root
    .MuiOutlinedInput-root {
    border-radius: 12px;
}

.liquidationTwo.wizardTheme
    .multisellerDashboardLayout
    .dashboardRt
    .customSelect
    .MuiOutlinedInput-root {
    border-radius: 12px;
}

.liquidationTwo.wizardTheme .multisellerDashboardLayout .dashboardRt .items-wrp {
    justify-content: space-between;
}

.liquidationTwo.wizardTheme
    .multisellerDashboardLayout
    .dashboardRt
    .nav-standard-top-area-search
    .MuiInputLabel-formControl {
    left: auto;
}

.liquidationTwo.wizardTheme
    .multisellerDashboardLayout
    .dashboardLt
    ul
    > .MuiListItem-root
    a.active
    span {
    color: #fff;
}

.primeBids .orderPickupStatus {
    background-color: #8a8a8a;
    color: #fff;
    padding: 25px;
    border-radius: 10px;
}

.primeBidsTracker .bord {
    background-color: #35353f;
    border-radius: 10px;
    padding: 20px;
    min-height: 135px;
    height: 100%;
    min-width: 634px;
    width: 100%;
}

.primeBidsTracker .bord:first-child {
    margin-bottom: 10px;
}

.orderStatus .MuiDialog-paper {
    border-radius: 10px;
}

.orderStatus .MuiDialog-paperWidthSm {
    max-width: 800px;
    width: 100%;
}

.primeBidsTracker .bord h1 {
    text-shadow: 1px 1px #fff;
    font-size: 32px;
    text-align: center;
}

.primeBidsTracker .trackrecord ul li:last-child.active::before {
    width: 101%;
    border-radius: 0px 135px 46px 0px;
}

.emursMck .np-pro-box {
    color: #fff;
}

.emursMck .multisellerDashboardLayout .dashboardRt .notificationPara {
    color: #fff;
}

.primeBidsTracker .trackrecord ul li:first-child.active::before {
    border-radius: 30px 0px 0px 89px;
    width: 104%;
    margin-left: -4px;
}

.primeBidsTracker .trackrecord ul li:nth-child(7).active {
    min-height: 126px;
}

.primeBidsTracker .trackrecord {
    position: absolute;
    width: 100%;
    max-width: calc(100% - 50px);
}

.primeBidsTracker .trackrecord ul {
    border-radius: 70px;
    list-style-type: none;
    border: 2px solid #7f7f7f;
    display: flex;
    background-image: linear-gradient(to right, #ffcb71, #fab43a);
    margin-bottom: 0px;
    /* min-height: 105px; */
}

.primeBidsTracker .trackrecord ul li {
    padding: 20px;
    border-radius: 80px 0px 0px 80px;
    text-align: center;
    color: #000;
    min-width: 135px;
    width: 100%;
}

.primeBidsTracker .trackrecord ul li h2 {
    font-size: 27px;
    margin-bottom: 4px;
    font-weight: 600;
}

.primeBidsTracker .trackrecord ul li p {
    font-size: 11px;
    margin-bottom: 0px;
    font-weight: 500;
}

.primeBidsTracker {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.primeBidsTracker .trackrecord ul li.pickLine {
    padding: 0px;
    min-width: unset;
    border: 3px solid #35353f;
    transform: skewX(-22deg);
    border-radius: 0px;
    width: 3px;
}

.primeBidsTracker .trackrecord ul li.active {
    position: relative;
    padding: 20px 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.primeBidsTracker .trackrecord ul li.active::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(#7dff8a, #45de1f);
    transform: skewX(-22deg);
    top: 0;
}

.primeBidsTracker .trackrecord ul li.active div {
    position: absolute;
    z-index: 3;
}

.primeBids .orderPickupStatus .popClrBtn {
    background-color: #35353f;
    border-radius: 50px;
    position: absolute;
    top: 0;
    right: 0;
    padding: 7px;
    color: #fff;
    box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.22);
}

.liquidationTwoDashboardLayout .dashboardLt ul > .MuiListItem-root {
    padding: 0;
}

.LotsAuction .auctionTit .customSelect {
    margin-bottom: 0;
}

.liquidationTwo .auctionTit .customSelect {
    margin-bottom: 0px;
}

.liquidationTwo .referralPrime {
    max-width: 1240px;
    width: 100%;
}

.emursMck .multisellerDashboardLayout table tr td {
    color: #fff !important;
}

.emursMck .multisellerDashboardLayout .favGrid table.bidHistory tr td {
    color: #000 !important;
}

.onlyLotSearch {
    width: 50px !important;
}

.onlyLotPlace {
    padding-right: 30px !important;
    padding-left: 15px !important;
}

.liquidationTwoDashboardLayout .dashboardLt ul > .MuiListItem-root a {
    color: #646464;
    font-size: 16px;
    padding: 20px;
    text-decoration: none;
    display: flex;
    width: 100%;
}

.liquidationTwoDashboardLayout .dashboardLt ul > .MuiListItem-root a .material-icons {
    margin-right: 10px;
}

.liquidationTwoDashboardLayout .dashboardLt ul > .MuiListItem-root a.active {
    background: var(--primColor);
    color: #fff;
}

.liquidationTwoDashboardLayout .dashboardLt ul > .MuiListItem-root a:not(.active):hover {
    background: var(--primColor);
    color: #fff;
    opacity: 0.75;
}

.liquidationTwoDashboardLayout .dashboardRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 280px);
}

.liquidationTwoDashboardLayout .dashboardRt .notificationPara {
    padding: 30px 0;
    text-align: center;
}

.liquidationTwoDashboardLayout .dashboardRt .maTitle {
    padding: 15px 30px;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 16%);
}

.liquidationTwoDashboardLayout .dashboardRt .dashTitle {
    font-size: 20px;
    font-weight: 600;
    color: #0e131f;
    text-align: start;
    text-transform: uppercase;
    margin-bottom: 0;
}

.liquidationTwoDashboardLayout .moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    -webkit-margin-end: 5px;
    margin-inline-end: 5px;
    padding: 5px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    font-size: 20px;
}

.liquidationTwoDashboardLayout .dashSubtitle {
    font-size: 16px;
    color: #212529;
    line-height: 24px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.liquidationTwo .multisellerDashboardLayout .table tbody button:hover {
    color: white !important;
}

.liquidationTwo .multisellerDashboardLayout .table tbody td:last-child button {
    min-width: max-content;
    margin-bottom: 10px;
}

.liquidationTwo .multisellerDashboardLayout .table tbody td:last-child button.subLotSearch {
    width: 50px;
    min-width: unset;
}

.liquidationTwo .dashboardLt .sdDvdrHdr {
    color: #afbbcc;
}

/* Responsive Code */

@media (max-width: 1024px) {
    .liquidationTwoDashboardLayout .dashboardLt {
        max-width: 25%;
    }

    .liquidationTwoDashboardLayout .dashboardRt {
        max-width: 75%;
    }

    .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
        background: #fff;
    }

    .DashfilterWrpper .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        color: #a6a6a6;
    }

    .DashfilterWrpper .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .DashfilterWrpper .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-family: var(--FuturaStdMedium);
        /* justify-content: space-between; */
    }

    .DashfilterWrpper .navRespLinks a.active,
    .DashfilterWrpper .navRespLinks a.active span {
        color: var(--primColor);
    }

    .DashfilterWrpper .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: center;
        font-family: var(--FuturaStdMedium);
        flex-wrap: wrap;
    }
}

@media (max-width: 991px) {
    .liquidationTwoDashboardLayout .dashboardRt {
        max-width: 100%;
    }

    .liquidationTwoDashboardLayout .dashboardLt {
        display: none;
    }

    .liquidationTwoDashboardLayout .dashboardRt .dashTitle {
        align-items: center;
        display: flex;
    }

    .liquidationTwoDashboardLayout .dashboardRt .dashTitle .MuiButtonBase-root.d-none {
        display: block !important;
    }

    .liquidationTwoDashboardLayout
        .dashboardRt
        .dashTitle
        .MuiButtonBase-root.d-none
        .MuiButton-label {
        text-align: left;
    }

    .liquidationTwoDashboardLayout
        .dashboardRt
        .dashTitle
        .MuiButtonBase-root.d-none
        .MuiButton-label
        .MuiButtonBase-root {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (max-width: 860px) {
    .trackrecord ul {
        flex-direction: column;
        border: none !important;
        border-radius: 20px !important;
    }

    .trackrecord ul .pickLine {
        display: none;
    }

    .trackrecord ul li {
        border: 1px solid #7f7f7f;
        border-radius: 0px !important;
        padding: 15px 20px !important;
    }

    .trackrecord ul li:first-child {
        border-radius: 20px 20px 0px 0px !important;
    }

    .trackrecord ul li:last-child {
        border-radius: 0px 0px 20px 20px !important;
    }

    .primeBidsTracker .bord {
        min-height: 255px;
    }

    .primeBidsTracker .trackrecord ul li.active::before {
        transform: unset;
        border-radius: 0px !important;
        width: 100% !important;
    }

    .primeBidsTracker .trackrecord ul li:last-child.active::before {
        border-radius: 0px 0px 20px 20px !important;
    }

    .primeBidsTracker .trackrecord ul li.active {
        min-height: 85px !important;
    }

    .primeBidsTracker .trackrecord ul li:first-child.active::before {
        margin-left: 0px;
        border-radius: 20px 20px 0px 0px !important;
    }

    .primeBidsTracker .bord h1 {
        font-size: 24px;
    }

    .primeBidsTracker .trackrecord {
        margin-top: 52px;
    }
}

@media (min-width: 768px) {
    .onlyPick {
        white-space: nowrap;
    }
}

@media (max-width: 600px) {
    .liquidationTwo .favGrid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}

@media (max-width: 475px) {
    .preferences-form-wrapper {
        overflow-x: auto;
    }

    .liquidationDashTabs .MuiTabs-flexContainer {
        white-space: pre-wrap;
        width: 100%;
    }

    .liquidationDashTabs .MuiTab-root {
        width: 48%;
        margin: 2px;
    }

    .liquidationTwoDashboardLayout .dashboardRt .maTitle {
        padding: 10px;
    }

    .liquidationTwoDashboardLayout .dashboardRt .dashTitle {
        font-size: 16px;
    }
}

@media (max-width: 425px) {
    .liquidationTwoDashboardLayout .dashboardRt .dashTitle {
        font-size: 14px;
    }
}
