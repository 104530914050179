.productViewStandalone .mpTop {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    margin-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
}

.liquidationTwo .checkRotate {
    height: 350px;
}

.liquidationTwo .shwMrBtnWrp {
    flex-wrap: nowrap;
    margin-bottom: 5px;
}

.liquidationTwo .multisellerProductDrawer .mssBidIntDet .mssBidMisc.multisellertwo {
    margin-bottom: 5px;
}

.productViewStandalone .plTitle span {
    display: block;
}

.productViewStandalone .plTitle span:first-child {
    font-size: 16px;
    color: #747474;
    margin-bottom: 8px;
}

.productViewStandalone .plTitle span:last-child {
    font-size: 25px;
    color: #2e2e2e;
    font-weight: 600;
    margin-bottom: 15px;
}

.productViewStandalone .image-gallery {
    width: 520px;
}

.productViewStandalone .image-gallery-thumbnails-container {
    text-align: start;
}

/* .productViewStandalone .image-gallery-thumbnails {
    width: 75px;
} */

.productViewStandalone .image-gallery-icon {
    filter: none;
    background: #00000036;
    padding: 10px;
}

.productViewStandalone .image-gallery-slide-wrapper.left {
    width: 100%;
}

.productViewStandalone .image-gallery-icon:hover {
    color: var(--primColor);
}

.productViewStandalone .image-gallery-thumbnail {
    border: 2px solid transparent;
    width: 75px;
}

.productViewStandalone .image-gallery-thumbnail.active,
.productViewStandalone .image-gallery-thumbnail:hover,
.productViewStandalone .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 0px;
}

.liquidationTwo.wizardTheme .productViewStandalone .image-gallery-thumbnail {
    border: 0.4px solid #00000066;
    border-radius: 8px;
    overflow: hidden;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight {
    margin-top: 45px;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .plTitle .subCat {
    display: none;
}

.liquidationTwo.wizardTheme .wizardAuctionPv_Paper .innerBaseRtt h5 span {
    font-weight: 300;
    margin-right: 10px;
}
.liquidationTwo.wizardTheme .wizardAuctionPv_Paper .innerBaseRtt h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}

.liquidationTwo.wizardTheme .wizardAuctionPv_Paper .innerBaseLft {
    border-right: 1px solid #e7e7e7;
    padding-right: 15px;
    margin-right: 15px;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 5px;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo {
    display: flex;
    width: 100%;
    justify-content: center;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .separator {
    width: 2px;
    background-color: #e7e7e7;
    margin: 10px 30px;
    flex-shrink: 0;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .left .top {
    font-size: 16px;
    font-weight: 400;
    color: var(--primColor);
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .biddingCnt
    .bid-wrapper
    .primButton
    button {
    background-color: var(--secColor);
    border-left: 0;
    font-weight: 700;
    font-size: 36px;
    color: black;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsCnt {
    padding-bottom: 20px;
    position: relative;
}

.liquidationTwo.wizardTheme .productViewStandalone .terms {
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
}

.liquidationTwo.wizardTheme .productViewStandalone .terms a {
    text-transform: none;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 700;
    color: black;
}

.liquidationTwo.wizardTheme .productViewStandalone .terms::after {
    position: absolute;
    content: '';
    width: 50%;
    height: 2px;
    background-color: #d9d9d9;
    bottom: 0px;
    left: 0;
    right: 0;
    margin: auto;
}

.liquidationTwo.wizardTheme .productViewStandalone .relatedProducts {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    /* grid-template-columns: repeat(auto-fit, 300px); */
    grid-gap: 15px;
    gap: 15px;
    overflow-x: auto;
    position: relative;
    grid-auto-flow: column;
    scroll-snap-type: x mandatory;
}

.liquidationTwo.wizardTheme .productViewStandalone .relatedProducts > * {
    scroll-snap-align: start;
    min-width: 300px;
    max-width: 1fr;
    /* max-width: 300px; */
}

.liquidationTwo.wizardTheme .productViewStandalone .socialShareImg {
    cursor: pointer;
}

.liquidationTwo.wizardTheme .productViewStandalone .relatedProducts > * {
    scroll-snap-align: start;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight form .customInput .MuiInputBase-root {
    border-radius: 8px 0 0 8px;
    height: 55px;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight form .customInput input {
    font-size: 24px;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight form .bid-wrapper button {
    border-radius: 0px 8px 8px 0;
    height: 55px;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .right .top {
    font-size: 16px;
    font-weight: 600;
    color: #aeaeae;
    line-height: 1;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .right .bottom.date {
    font-size: 16px;
    font-weight: 600;
    color: black;
    text-decoration: none;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .left .bottom {
    font-size: 36px;
    font-weight: 700;
    color: var(--primColor);
    line-height: 1;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .right .bottom {
    font-size: 26px;
    font-weight: 700;
    text-decoration: line-through;
}

.liquidationTwo.wizardTheme .productViewStandalone .sdTimer .customFlexBox {
    flex-direction: column;
    grid-gap: 0;
    gap: 0;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .left,
.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.liquidationTwo.wizardTheme .productViewStandalone .lftCntiner .favoriteWrp {
    margin: 6px 0;
}

.liquidationTwo.wizardTheme .productViewStandalone .lftCntiner .favoriteWrp .favoriteCheck {
    height: 24px;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .left {
    align-items: flex-end;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .right {
    align-items: flex-start;
    justify-content: center;
}

.liquidationTwo.wizardTheme .productViewStandalone .sdTimer {
    display: flex;
    align-items: center;
}

.liquidationTwo.wizardTheme .productViewStandalone .sdTimer .timer-icon {
    font-size: 36px;
}

.liquidationTwo.wizardTheme .productViewStandalone .sdTimer .customFlexBox {
    align-items: flex-start;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .sdTimer
    .customFlexBox
    .actualClass
    .actualTimer {
    font-size: 12px;
    color: #888888;
    font-weight: 600;
}

.liquidationTwo.wizardTheme .productViewStandalone .sdTimer .customFlexBox .actualClass {
    line-height: 1;
}

.liquidationTwo.wizardTheme .productViewStandalone .sdTimer .customFlexBox .timeClass {
    font-size: 36px;
    color: var(--primColor);
    font-weight: 600;
    line-height: 1;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .plTitle {
    text-align: center;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .plTitle span {
    text-align: center;
    max-width: 540px;
    font-size: 36px;
    position: relative;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .plTitle span::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background-color: #d9d9d9;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
}

.liquidationTwo.wizardTheme .productViewStandalone .image-gallery-thumbnail.active,
.liquidationTwo.wizardTheme .productViewStandalone .image-gallery-thumbnail:hover,
.liquidationTwo.wizardTheme .productViewStandalone .image-gallery-thumbnail:focus {
    outline: none;
    border: 2px solid var(--primColor);
    border-radius: 8px;
    overflow: hidden;
}

.liquidationTwo.wizardTheme .productViewStandalone .image-gallery-svg {
    height: 34px;
    width: 34px;
    color: black;
    stroke: black;
    stroke-width: 2;
}

.liquidationTwo.wizardTheme .productViewStandalone .image-gallery-slide-wrapper {
    border: 0.4px solid #00000066;
    border-radius: 8px;
    overflow: hidden;
}

.liquidationTwo.wizardTheme .productViewStandalone .image-gallery-icon {
    background: none;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .image-gallery-thumbnail
    .image-gallery-thumbnail-image {
    height: 60px;
    width: 60px;
    object-fit: cover;
}

.liquidationTwo.wizardTheme .productViewStandalone .image-gallery-thumbnails-container {
    margin-top: 0;
}

.liquidationTwo.wizardTheme .productViewStandalone .locationItemWrapper .location {
    display: flex;
    min-width: fit-content;
    padding: 20px 8px;
    background-color: #fcb04091;
    align-items: center;
    border-radius: 8px;
}

.liquidationTwo.wizardTheme .productViewStandalone .locationItemWrapper .locationItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.liquidationTwo.wizardTheme .productViewStandalone .locationItemWrapper {
    width: auto;
    margin-left: auto;
    margin-top: 60px;
}

.liquidationTwo.wizardTheme .productViewStandalone .locationItemWrapper .location-pickup {
    font-size: 16px;
    font-weight: 600;
}

.liquidationTwo.wizardTheme .productViewStandalone .locationItemWrapper .location-place {
    font-size: 18px;
    font-weight: 700;
}

.liquidationTwo.wizardTheme .productViewStandalone .locationItemWrapper .location-notes {
    font-style: italic;
    font-size: 14px;
    font-weight: 400;
    display: inline-block;
    word-wrap: normal;
    max-width: 260px;
    text-transform: capitalize;
}

.liquidationTwo.wizardTheme .productViewStandalone .lftCntiner .productInfo,
.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo-mobile {
    margin-top: 16px;
}

.liquidationTwo.wizardTheme .productViewStandalone .lftCntiner .productInfo .productInfo-id {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo-mobile .productInfo-id {
    display: flex;
    justify-content: space-around;
    gap: 8px;
}

.liquidationTwo.wizardTheme .productViewStandalone .lftCntiner .productInfo .productInfo-id .info,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-id
    .info {
    font-size: 16px;
    font-weight: 600;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-id
    .productInfo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.liquidationTwo.wizardTheme .productViewStandalone .lftCntiner .productInfo .productInfo-more {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
}

.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo-mobile .productInfo-more {
    margin-top: 30px;
    display: flex;
}

.liquidationTwo.wizardTheme .buyerAssurance .row .customCheckbox .loa-checkbox-label {
    white-space: pre-line;
}

.liquidationTwo.wizardTheme .buyerAssurance .row .MuiFormControlLabel-root {
    display: flex;
    justify-content: center;
    background-color: #fceefc;
    border-radius: 10px;
    margin-left: 0;
    margin-right: 0;
}

.liquidationTwo.wizardTheme .productViewStandalone .search-wrapper h2 {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.liquidationTwo.wizardTheme .productViewStandalone .search-wrapper h2 span {
    font-size: 32px;
}

.liquidationTwo.wizardTheme .buyerAssurance .row .col-12 {
    display: flex;
    justify-content: center;
}

.liquidationTwo.wizardTheme .gpBidCart {
    flex-wrap: wrap;
}

.liquidationTwo.wizardTheme .buyerAssurance .row {
    margin: 0;
}

.liquidationTwo.wizardTheme .buyerAssurance .row .customCheckbox {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.liquidationTwo.wizardTheme .productViewStandalone .lftCntiner .productInfo .productInfo-notes,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-notes {
    font-size: 18px;
    font-weight: 400;
}

.liquidationTwo.wizardTheme .productViewStandalone .lftCntiner .productInfo .productInfo-more-wrp {
    display: flex;
    justify-content: space-around;
    width: calc(100% - 110px);
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-more-wrp {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .lftCntiner
    .productInfo
    .productInfo-more
    .productInfo-item,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-more
    .productInfo-item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .lftCntiner
    .productInfo
    .productInfo-more
    .productInfo-item
    .research,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-more
    .productInfo-item
    .research {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .lftCntiner
    .productInfo
    .productInfo-id
    .separator,
liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-id
    .separator {
    color: #a0a0a0;
    font-weight: 900;
}

.liquidationTwo.wizardTheme .productViewStandalone .lftCntiner .productInfo .productInfo-id .static,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-id
    .static {
    font-size: 16px;
    font-weight: 400;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .lftCntiner
    .productInfo
    .productInfo-more
    .productInfo-item
    .static,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-more
    .productInfo-item
    .static {
    font-size: 16px;
    font-weight: 400;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .lftCntiner
    .productInfo
    .productInfo-more
    .productInfo-item
    .info.condition,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-more
    .productInfo-item
    .info.condition {
    background-color: #fceefc;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    padding: 13px 15px;
    justify-content: center;
    align-items: center;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .lftCntiner
    .productInfo
    .productInfo-notes
    .notes-cnt,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-notes
    .notes-cnt {
    margin-left: 20px;
    margin-top: 20px;
}

.liquidationTwo.wizardTheme .productViewStandalone .relatedProdTit {
    text-align: center;
    font-size: 32px;
    font-weight: 700;
}

.liquidationTwo.wizardTheme .social {
    display: flex;
    padding: 10px;
    gap: 5px;
}

.liquidationTwo.wizardTheme .productViewStandalone .lftCntiner .productInfo .productInfo-desc,
.liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo-mobile .productInfo-desc {
    margin-left: 20px;
    margin-top: 20px;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .lftCntiner
    .productInfo
    .productInfo-desc
    .static,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-desc
    .static {
    font-weight: 600;
    font-size: 18px;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .lftCntiner
    .productInfo
    .productInfo-notes
    .notes,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-notes
    .notes {
    background-color: #fceefc;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    padding: 13px 15px;
    justify-content: center;
    align-items: center;
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .lftCntiner
    .productInfo
    .productInfo-more
    .productInfo-item
    .research
    .info-id,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-more
    .productInfo-item
    .research
    .info-id {
    font-size: 14px;
    font-weight: 600;
    color: var(--primColor);
}

.liquidationTwo.wizardTheme
    .productViewStandalone
    .lftCntiner
    .productInfo
    .productInfo-more
    .productInfo-item
    .research
    .info-static,
.liquidationTwo.wizardTheme
    .productViewStandalone
    .pvsRight
    .productInfo-mobile
    .productInfo-more
    .productInfo-item
    .research
    .info-static {
    font-size: 16px;
    font-weight: 600;
}

.productViewStandalone .image-gallery-thumbnail .image-gallery-thumbnail-image {
    height: 60px;
    object-fit: cover;
    border-radius: 0px;
}

.productViewStandalone .image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 100%;
    max-height: 350px;
    min-height: 300px;
    cursor: initial;
}

.productViewStandalone .image-gallery-content .image-gallery-slide > div {
    background: #efefef;
}

.productViewStandalone .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    max-height: 100vh;
}

.productViewStandalone .productInfo h5 {
    font-size: 18px;
    color: #1e1e1e;
    font-weight: 600;
    margin-bottom: 25px;
}

.productViewStandalone .productInfo h5 span {
    font-size: 15px;
    font-weight: 500;
    width: 200px;
    display: inline-block;
}

.productViewStandalone .pvsCnt {
    display: grid;
    grid-template-columns: 520px auto;
    gap: 20px;
}

.productViewStandalone .sl-pbids.slidView {
    width: auto;
    margin-top: 0;
    margin-left: auto;
}

.productViewStandalone .biddingCnt .customInput {
    margin-bottom: 15px;
}

.productViewStandalone form:last-child .biddingCnt .customInput {
    margin-bottom: 0px;
}

.productViewStandalone .mssBidItem {
    display: flex;
}

.productViewStandalone .mssBidHistory {
    float: right;
    height: 45px;
    width: auto;
    text-transform: initial;
    color: var(--primColor);
}

.productViewStandalone .mssBidHistory .material-icons {
    margin-right: 10px;
}

@media (max-width: 1366px) {
    .liquidationTwo .productViewStandalone .sl-pbids.slidView {
        margin-left: 0;
    }

    .liquidationTwo .productViewStandalone .mssBidItem .productInfo {
        margin-right: 10px;
    }

    .liquidationTwo .productViewStandalone .mssBidItem {
        flex-wrap: wrap;
    }
}

@media (max-width: 990px) {
    .liquidationTwo .productViewStandalone .pvsCnt {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
}

@media (max-width: 800px) {
    .liquidationTwo.wizardTheme .productViewStandalone .sdTimer .customFlexBox .timeClass {
        font-size: 28px;
        font-weight: 600;
    }
}

@media (max-width: 645px) {
    .liquidationTwo.wizardTheme .productViewStandalone .pvsRight form .customInput input {
        font-size: 24px;
    }

    .liquidationTwo.wizardTheme
        .productViewStandalone
        .pvsRight
        .biddingCnt
        .bid-wrapper
        .primButton
        button {
        font-size: 24px;
        font-weight: 600;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .pvsRight {
        margin-top: 10px;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .right .top {
        font-size: 14px;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .sdTimer .customFlexBox .timeClass {
        font-size: 20px;
        font-weight: 600;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .right .bottom.date {
        font-size: 14px;
        font-weight: 600;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .separator {
        margin: 10px 20px;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .locationItemWrapper {
        margin-top: 20px;
    }
}

@media (max-width: 600px) {
    .productViewStandalone .image-gallery {
        width: unset !important;
    }

    .liquidationTwo .productViewStandalone .productInfo h5 {
        display: flex;
        flex-wrap: wrap;
        font-size: 14px;
        margin: 15px 0;
        line-height: 23px;
    }

    .liquidationTwo.wizardTheme .wizardAuctionPv_Paper {
        display: block;
    }

    .liquidationTwo.wizardTheme .wizardAuctionPv_Paper .innerBaseLft {
        border-right: unset;
        padding-right: unset;
        margin-right: unset;
    }

    .liquidationTwo .productViewStandalone .mssBidItem {
        flex-wrap: wrap;
    }

    .liquidationTwo .productViewStandalone .biddingCnt .primButton,
    .liquidationTwo .productViewStandalone .biddingCnt .secButton {
        width: 100%;
        max-width: 145px;
    }

    .liquidationTwo .productViewStandalone .pvsBottom .tabBody {
        padding: 10px;
    }
}

@media (max-width: 500px) {
    .liquidationTwo.wizardTheme .productViewStandalone .locationItemWrapper .location-pickup {
        font-size: 13px;
        font-weight: 500;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .locationItemWrapper .location-place {
        font-size: 15px;
        font-weight: 600;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .sdTimer .timer-icon {
        font-size: 28px;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .left .bottom {
        font-size: 28px;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .pvsRight .plTitle span {
        font-size: 28px;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .right .bottom {
        font-size: 18px;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .sdTimer .customFlexBox .timeClass {
        font-size: 18px;
        font-weight: 600;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .right .bottom.date {
        font-size: 14px;
        font-weight: 600;
    }

    .liquidationTwo.wizardTheme .productViewStandalone .pvsRight .productInfo .separator {
        margin: 10px 20px;
    }
}
