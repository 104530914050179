.liquidationTwo .mainHeader {
    height: 100%;
    position: relative;
    top: 0;
    z-index: 999;
    background: var(--headerColor);
    color: var(--headerFontColor);
    padding: 10px 0;
    border-bottom: none;
    /* overflow: hidden; */
    box-shadow: 0px 3px 6px rgb(0 0 0 / 12%);
    /* position: sticky; */
    top: 0;
}

.BidAuction .mainHeader {
    background-color: #1f3c77 !important;
}

.BidAuction .categoriesInMob button {
    color: #fff;
    background-color: var(--primColor);
    width: 100%;
}

.BidAuction .mainHeader .headRt {
    margin-left: 60px;
}

.BidAuction .mainHeader .nav-standard-logo .headerLogo {
    max-width: 190px;
    object-fit: contain;
    height: 76px;
}

.liquidationTwo.primeBids .nav-standard-top-area-search-input {
    height: 58px;
    background-color: #f5f5f5;
    padding-left: 1.25rem;
    border-radius: 16px;
}

.categoriesDropMenu .PmCategoryImage-root {
    width: 30px;
    height: 30px;
    border-radius: 100px;
    overflow: hidden;
    margin: auto;
    cursor: pointer;
    display: block;
    margin-right: 15px;
}

.categoriesButton {
    text-transform: unset !important;
    font-size: 16px !important;
}

.categoriesDropMenu,
.dealsDropMenu {
    margin-bottom: 13px;
}

.dealsDropMenu .material-icons-outlined {
    color: var(--bannerTextColor);
}

.beachLiquidation .dealsDropMenu .material-icons-outlined {
    color: var(--primColor);
}

.categoriesDropMenu .PmCategoryImage-root .PmCategoryImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.categoriesDropMenu .PmCategoryList-root {
    display: flex;
    align-items: center;
}

.categoriesDropMenu .PmCategoryList-root .PmCategoryTitle {
    font-size: 14px;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
}

.liquidationTwo a {
    color: var(--primColor);
}

#simple-menu_1 .MuiPaper-root {
    transform: translateX(60px) !important;
    -webkit-transform: translateX(60px) !important;
    -moz-transform: translateX(60px) !important;
    -ms-transform: translateX(60px) !important;
    -o-transform: translateX(60px) !important;
}

.liquidationTwo .headLt .nav-standard-logo {
    display: flex;
    align-items: center;
    justify-content: center;
}

.liquidationTwo .mainHeader .headRt ul {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-left: 4%;
}

.liquidationTwo .mainHeader .headRt .hdrRtLt {
    width: 100%;
}

.liquidationTwo .mainHeader .headRt ul li {
    color: inherit;
    white-space: pre;
}

.liquidationTwo .mainHeader .headRt ul a {
    color: #49454f;
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    -webkit-transition: 200ms color ease-in-out;
    -o-transition: 200ms color ease-in-out;
    transition: 200ms color ease-in-out;
    position: relative;
}

.BidAuction .mainHeader .headRt ul a.signInButton {
    border: 1px solid white;
    padding: 1px 8px;
    border-radius: 6px;
    text-align: center;
}

.liquidationTwo .mainHeader .headRt ul a.active {
    color: var(--primColor);
}

.headerSellBtn a {
    display: flex;
    align-items: center;
}

.headerSellBtn .material-icons {
    margin-left: 10px;
}

.mainHeader .headRt ul li button {
    color: inherit;
    font-size: 16px;
    font-weight: 600;
    text-transform: initial;
}

.mobHeader .headRt ul li button {
    min-width: initial !important;
    margin: 0;
    width: 40px;
}

.mainHeader .headRt ul li button .material-icons {
    padding-left: 10px;
}

.BidAuction .mainHeader .headRt ul li button .material-icons {
    padding: 0;
}

.mainHeader .headRt ul li button.active {
    color: var(--primColor);
}

.liquidationTwo .mainHeader .headRt ul a:after {
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 3px;
    left: 50%;
    position: absolute;
    background: inherit;
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
}

.mainHeader .headRt ul a.active:after {
    width: 100%;
    left: 0;
}

.mainHeader .headRt ul a:hover:after {
    width: 100%;
    left: 0;
}

.mainHeader .headRt ul li:not(:last-child) {
    margin-right: 30px;
}

.BidAuction .mainHeader .headRt ul li:not(:last-child) {
    margin-right: 15px;
}

.BidAuction .mainHeader .headRt ul li a {
    color: white;
}

.BidAuction .mainHeader .headRt ul li button {
    min-width: initial !important;
}

.primeBids .mainHeader .headRt ul li.headerSellBtn:not(:last-child) {
    display: none;
}

.nav-standard-top-area-search-inner {
    position: relative;
    width: 100%;
}

.liquidationTwo .mainHeader .nav-standard-top-area-search-inner {
    width: 100%;
    padding-right: 45px;
    border: 1px solid #9b9b9b;
    border-radius: 4px;
    position: relative;
}

.BidAuction .mainHeader .nav-standard-top-area-search-inner {
    padding-right: 0;
    border: 1px solid #f2f2f7;
    border-radius: 100px;
    overflow: hidden;
}

.BidAuction .mainHeader .nav-standard-top-area-search-inner button.lt {
    border-right: 1px solid #9b9b9b;
    border-radius: 100px;
    height: 50px;
}

.BidAuction
    .mainHeader
    .nav-standard-top-area-search-inner
    .nav-standard-top-area-search-textInput.rt {
    border-left: 1px solid #9b9b9b;
    border-radius: 100px;
    padding: 10px;
    height: 50px;
}

.liquidationTwo .mainHeader .srchLstCntnr {
    width: 100%;
    max-width: 800px;
    margin-left: 20px;
}

.BidAuction .mainHeader .srchLstCntnr {
    max-width: fit-content;
    margin-left: 0;
}

.liquidationTwo.primeBids .mainHeader .srchLstCntnr {
    max-width: 900px;
    margin-left: 10px;
}

.liquidationTwo .mainHeader .nav-standard-top-area-search-inner .srchIcon {
    color: var(--primColor);
    position: absolute;
    top: 11px;
    left: 10px;
    cursor: pointer;
}

.liquidationTwo .mainHeader .nav-standard-top-area-search-inner > div {
    display: flex;
}

.BidAuction .nav-standard-top-area-search-input:first-child {
    border-radius: 4px;
    /* border-right: 1px solid #9b9b9b; */
    box-shadow: none;
}

.BidAuction .nav-standard-top-area-search-textInput input::placeholder {
    color: #49454f;
}

.BidAuction .nav-standard-top-area-search-inner {
    display: flex;
    align-items: center;
}

.BidAuction .nav-standard-top-area-search-input {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    height: 45px;
    margin-right: 0;
    border-radius: 0px;
    padding-left: 40px;
    padding-right: 80px;
}

.BidAuction .nav-standard-top-area-search-sumbit {
    height: 45px;
}

.liquidationTwo .nav-standard-top-area-search-inner select {
    border: none;
    padding: 4px 15px 4px 15px;
    color: #333;
    width: 100%;
    display: block;
    height: 45px;
    margin-right: 0;
    background: #fff;
    border-radius: 0px;
    width: 220px;
    font-size: 15px;
    color: #9b9b9b;
    background: #f5f5f5;
    border-left: 1px solid #9b9b9b;
}

.liquidationTwo .nav-standard-top-area-search-input::placeholder {
    font-size: 15px;
    color: #9b9b9b;
}

.liquidationTwo .nav-standard-top-area-search-inner select:focus {
    box-shadow: none;
    outline: 0;
}

.nav-standard-top-area-search-input::placeholder {
    color: #333;
}

.nav-standard-top-area-search-input:focus {
    outline: none;
    border-color: #ddd;
}

.liquidationTwo .nav-standard-top-area-search-sumbit {
    width: 100px;
    height: 45px;
    padding: 0 7px;
    line-height: 30px;
    display: inline-flex;
    text-align: left;
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: var(--secColor);
    border: none;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
}

.liquidationTwo .mainHeader .headLt .headerLogo {
    width: 180px;
    object-fit: contain;
    height: 70px;
    margin-right: 20px;
}

.headerDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 300px;
    background: #fff;
}

.headerDrawer .mobShopLocation.MuiListItem-root {
    flex-wrap: wrap;
}

.headerDrawer .mobShopLocation.MuiListItem-root p {
    font-size: 14px;
    padding-inline-start: 8px;
    margin: 0;
}

.headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
    width: 100%;
    justify-content: space-between;
}

.headerDrawer .headerDrawerNavLinks {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: calc(100vh - 100px);
    height: auto;
}

.headerDrawer .headerDrawerNavLinks hr {
    width: 100%;
}

.respNav,
.respNavBtn {
    display: none !important;
}

.respHeaderSearch .MuiPaper-root {
    padding: 15px;
}

.respHeaderSearch .nav-standard-top-area-search-input {
    height: 50px;
}

.respHeaderSearch button {
    height: 100%;
}

.headerAuthBtn {
    background: #f5f5f5;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 10%);
    height: 45px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.headerAuthBtn .material-icons {
    color: #2e2e2e;
    font-size: 24px;
    margin-left: 12px;
}

.mainHeader .headRt ul .headerAuthBtn a {
    color: #2e2e2e;
    font-weight: 600;
}

.mainHeader .headRt .headerIcons {
    min-width: initial;
    width: 45px;
    height: 45px;
    border-radius: 50%;
}

.mainHeader .headRt .headerIcons .material-icons {
    padding: 0;
    margin: 0;
}

.liquidationTwo .headBottomNav {
    border-top: 1px solid #dbdbdb;
    padding: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.liquidationTwo .headBottomNav ul {
    list-style: none;
    margin: 0;
    padding-left: 0;
}

.liquidationTwo .headBottomNav li,
.liquidationTwo .headBottomNav li a {
    font-size: 15px;
    font-weight: 500;
    color: #2e2e2e;
    text-decoration: none;
    position: relative;
    cursor: pointer;
}

.liquidationTwo .headBottomNav a:hover,
.liquidationTwo .headBottomNav a.active {
    color: var(--secColor);
}

.liquidationTwo .headBottomNav a:after {
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    background: none repeat scroll 0 0 transparent;
    bottom: -10px;
    content: '';
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    background: inherit;
    width: 0;
}

.liquidationTwo .headBottomNav a:hover:after,
.liquidationTwo .headBottomNav a.active:after {
    width: 0%;
    left: 0;
    background: var(--secColor);
}

.liquidationTwo .headBottomNav li > button .MuiButton-label {
    font-size: 16px;
    font-weight: 600;
    color: #2e2e2e;
    text-transform: capitalize;
}

.liquidationTwo .headBottomNav li:first-child {
    margin-right: 25px;
}

.liquidationTwo .contactNav li a:after {
    display: none;
}

.liquidationTwo .contactNav li:first-child {
    border-right: 1px solid #d0d0d0;
    margin-right: 16px;
    padding-right: 16px;
}

.liquidationTwo .contactNav .socialIcons a {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    background: var(--primColor);
    color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

.liquidationTwo .contactNav .socialIcons a i {
    margin: 0 auto;
}

.liquidationTwo .contactNav .socialIcons a:not(:last-child) {
    margin-right: 10px;
}

.catgDrawer .MuiDrawer-paper {
    width: 350px;
}

.catgDrawer .noCatg {
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.catgDrawer .noCatg img {
    width: 200px;
    height: auto;
    object-fit: contain;
}

.catgDrawer .noCatg h6 {
    font-size: 16px;
    margin: 15px 0 0;
}

.catgDrawer .catgHead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    position: relative;
}

.catgDrawer .catgHead h3 {
    font-size: 24px;
    font-weight: 600;
    color: var(--primColor);
    margin: 0;
    position: relative;
}

.catgDrawer .viewAllAuc {
    color: var(--primColor);
    position: sticky;
    bottom: 0;
    background: #fff;
    padding: 10px;
    border-radius: 0;
}

.catgDrawer .viewAllAuc:hover {
    background: var(--primColor);
    color: #fff;
}

.catgDrawer .viewAllAuc .MuiButton-label {
    font-weight: 500;
}

.catgDrawer .catgHead h3::after {
    position: absolute;
    content: '';
    width: 70px;
    height: 3px;
    bottom: -10px;
    left: 0;
    border-radius: 5px;
    background: var(--primColor);
}

.catgDrawer .catgHead button {
    min-width: auto;
    position: absolute;
    background: var(--primColor);
    top: 20px;
    right: 0;
    width: 35px;
    height: 25px;
    color: #fff;
    border-radius: 25px 0 0 25px;
}

.catgDrawer .catgLinks {
    margin: 0;
    margin-top: 20px;
}

.catgDrawer .catgLinks .homeCatgInner {
    padding: 15px 20px;
}

.homeCatgInner.auctions .multiSellerTimerView h6 {
    font-size: 15px;
}

.catgDrawer.auctions .MuiDrawer-paper {
    width: 550px;
}

.catgDrawer.auctions .auctionListInner h5 {
    font-weight: 600;
    font-size: 18px;
}

.catgDrawer.auctions .timerCnt {
    display: inline-block;
    margin-left: auto;
}

.catgDrawer.auctions .catgLinks .homeCatgInner:not(:last-child) {
    border-bottom: 3px solid #f7f7f7;
}

.liquidationTwo .headRt .primButton {
    color: white;
    margin-right: 25px;
}

.liquidationTwo .headRt .headerBtmDivider {
    width: 2px;
    height: 25px;
    margin: 0 10px !important;
    background: var(--primColor);
}

.liquidationTwo .mainHeader .headRt ul.hdrRtLt li {
    margin-right: 0;
}

.liquidationTwo .mainHeader .headRt ul li .lin-magnifier {
    border-radius: 0 4px 4px 0;
    color: #fff;
    text-transform: uppercase;
}

.liquidationTwo .mainHeader .headRt ul li .lin-magnifier .material-icons {
    padding-left: 0;
    color: inherit;
}

.liquidationTwo.hyperMicro .mainHeader .headRt ul li .lin-magnifier {
    color: var(--primColor);
}

.liquidationTwo .mainHeader .lgSgnBtn {
    border: 1px solid var(--primColor);
    color: var(--primColor);
    height: 45px;
}

.liquidationTwo .sdDvdrHdr {
    padding: 0 20px;
    color: #c1c6cd;
    font-weight: bold;
    font-size: 14px;
}

.liquidationTwo .sdBrDvdr {
    border-top: 2px solid #c1c6cd;
    margin-top: 5px;
}

.nocardBanner {
    background: #4aa168;
    padding: 12px 20px;
}

.nocardBanner p {
    color: white;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    cursor: pointer;
}

.liquidationTwo .textSliderWrapper {
    background: var(--secColor);
    color: white;
    text-align: center;
}

.liquidationTwo .lctnFltr {
    display: flex;
    align-items: center;
    font-weight: 600;
    margin-left: 20px;
}

.liquidationTwo .lctnFltr .lctnIconVw {
    color: var(--primColor);
    font-size: 30px;
    margin-right: 5px;
}

.liquidationTwo.primeBids .lctnFltr .lctnIconVw {
    font-size: 22px;
}

.liquidationTwo .lctnFltr label {
    color: gray;
    font-size: 14px;
    margin-bottom: 0px;
    white-space: pre;
}

.liquidationTwo.primeBids .lctnFltr label {
    display: none;
}

.liquidationTwo .lctnFltr .fltrValue {
    width: max-content;
    display: flex;
    align-items: center;
    color: var(--primColor);
    padding: 0;
    font-size: 17px;
}

.liquidationTwo .lctnFltr .fltrValue .MuiButton-label {
    text-transform: initial;
}

.termwrap {
    max-height: 700px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.lctnDialogWrapper .rtInfoWrpr {
    padding: 20px 30px;
    padding-top: 80px;
}

.lctnDialogWrapper .termswrapper {
    padding-top: 0px !important;
    padding: 30px !important;
}

.lctnDialogWrapper .termswrapper h4 {
    margin-top: 6px !important;
    margin-left: 10px;
}

.lctnDialogWrapper .termswrapper input[type='checkbox'] {
    height: 17px;
    width: 17px;
}

.lctnDialogWrapper .termswrapper a {
    color: #313131 !important;
    text-decoration: none;
}

.lctnDialogWrapper .termswrapper hr {
    border-top: 1px solid var(--primColor);
}

.lctnDialogWrapper .rtInfoWrpr h3 {
    font-size: 16px;
    font-weight: 400;
}

.lctnDialogWrapper .rtInfoWrpr label {
    font-size: 17px;
    margin-top: 25px;
    margin-bottom: 14px;
}

.lctnDialogWrapper .rtInfoWrpr .crntLctn {
    font-size: 20px;
    color: var(--primColor);
    font-weight: 500;
    border: 1px solid #e8e8e8;
    padding: 5px 10px;
    display: flex;
    align-items: center;
}

.lctnDialogWrapper .rtInfoWrpr .crntLctn .material-icons {
    margin-right: 5px;
}

.lctnDialogWrapper .rtInfoWrpr h4 {
    font-size: 16px;
    margin-top: 41px;
    position: relative;
}

.lctnDialogWrapper .rtInfoWrpr .othLctn {
    font-size: 17px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 10px;
}

.splEvntsWrpr .divHdrTxt {
    margin-bottom: 30px;
}

.lctnDialogWrapper .imgWrpr {
    width: 100px;
    height: 150px;
    position: absolute;
    top: -76px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
}

.lctnDialogWrapper .imgWrpr img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.slctLctnPopup .MuiPaper-root {
    overflow: visible;
}

.mblNavFooter {
    position: fixed;
    bottom: -1px;
    left: 0;
    z-index: 1000;
    background: white;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    height: 84px;
    padding: 5px;
    box-shadow: 0 0 10px #0000001f;
}

.mblNavFooter.noAuth {
    grid-template-columns: 1fr 1fr;
    box-shadow: 0px 0px 10px #0000001f;
}

.mblNavFooter.noAuth button {
    height: 100%;
}

.mblNavFooter .ftrNav {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.liquidationTwo .mblNavFooter .respNavBtn {
    margin-left: 0px !important;
    height: 40px;
}

.mblNavFooter .ftrNav button {
    transition: ease-in-out 0.5s;
}

.mblNavFooter .ftrNav.active button {
    border-radius: 100px;
    background: var(--primColor);
    transform: translateY(-15px);
    box-shadow: 0 4px 10px #00000036;
}

.mblNavFooter .ftrNav.headCart label {
    text-align: center;
}

.mblNavFooter .ftrNav.headCart.active label {
    color: var(--primColor);
    padding-left: 0px;
    text-align: center;
}

.mblNavFooter .activeColor {
    color: var(--primColor);
}

.mblNavFooter .ftrNav.active button .material-icons,
.mblNavFooter .ftrNav.active button .MuiSvgIcon-root {
    color: white;
}

.liquidationTwo .mainHeader .headRt ul li.adtnlNvBtn button {
    background: var(--secColor);
    color: white;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
}

.liquidationTwo.hyperMicro .mainHeader .headRt ul li.adtnlNvBtn button {
    color: var(--primColor);
}

.liquidationTwo.hyperMicro .mainHeader .headRt ul li.adtnlNvBtn button svg path {
    fill: var(--primColor);
}

.liquidationTwo.hyperMicro .mainHeader .headRt ul li.adtnlNvBtn button svg path:first-child {
    fill: #fff;
}

.liquidationTwo .mainHeader .headRt ul li.adtnlNvBtn button .material-icons {
    margin-left: 0;
    padding-left: 0px;
}

.liquidationTwo .mainHeader .headRt ul li.adtnlNvBtn button svg {
    width: 30px;
    height: 18px;
    margin-right: 5px;
}

.liquidationTwo .pdtStsInfo.listViewCdn {
    display: none;
}

/* primeBids css */
.liquidationTwo.primeBids .lctnFltr {
    display: none;
}

.liquidationTwo.primeBids .lctnFltr.primeLoc {
    display: flex !important;
    align-items: center;
}

.liquidationTwo.primeBids .mainHeader .lgSgnBtn {
    padding: 6px 12px;
}

.liquidationTwo.primeBids .nav-standard-top-area-search-input::placeholder {
    color: #7a7a7a;
}

.liquidationTwo.primeBids .mainHeader .nav-standard-top-area-search-inner .srchIcon {
    display: none;
}

.liquidationTwo.primeBids .mainHeader .nav-standard-top-area-search-inner {
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
    border: 1px solid #f5f5f5;
}

.liquidationTwo.primeBids .nav-standard-top-area-search-sumbit {
    background: var(--linerGadient);
}

.liquidationTwo.primeBids .mainHeader .headRt ul li .lin-magnifier {
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
    height: 58px;
    padding: 0 1.5rem;
    width: auto;
}

.liquidationTwo.primeBids .mainHeader .headRt ul.hdrRtLt li {
    margin-left: 0;
}

.BidAuction .mainHeader .mob-search-icon {
    display: none;
}

.BidAuction .mainHeader .header-search {
    display: block;
}

/* Prime bids */

/* Responsive Code */

@media (max-width: 1366px) {
    .liquidationTwo .headBottomNav li:not(:last-child) {
        margin-right: 25px;
        font-size: 14px;
    }

    .BidAuction .mainHeader .nav-standard-top-area-search-inner .MuiPaper-root {
        width: 410px;
    }
}

@media (max-width: 1100px) {
    .liquidationTwo .mainHeader .nav-standard-top-area-search-inner {
        width: auto;
        max-width: 520px;
        min-width: 350px;
    }

    .liquidationTwo .headBottomNav li:not(:last-child) {
        margin-right: 25px;
    }
}

@media (min-width: 1000px) and (max-width: 1100px) {
    .liquidationTwo .mainHeader .srchLstCntnr {
        margin-left: 0px;
    }
}

@media (max-width: 1024px) {
    .mainHeader {
        height: 80px;
    }

    .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 10px;
    }

    header .headLt img {
        max-width: 180px;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .deskNav {
        display: none !important;
    }

    .respNav {
        display: block !important;
    }

    .respNavBtn {
        display: inline-flex !important;
        min-width: max-content !important;
        margin-inline-start: 10px !important;
        color: var(--headerFontColor) !important;
    }

    .liquidationTwo .respNavBtn {
        color: var(--primColor);
    }

    .liquidationTwo .respNavBtn .material-icons,
    .crtIconBtn .MuiSvgIcon-root {
        color: var(--primColor);
    }

    .liquidationTwo .mnLftNav.respNavBtn {
        margin-inline-start: 0 !important;
    }

    .headRt ul {
        list-style: none;
        margin: 0;
    }

    .headRt.respNav .navRespLinks {
        padding-inline-start: 0;
        list-style-type: none;
        flex-wrap: wrap;
        margin: 0;
        margin-top: 15px;
    }

    .headRt.respNav .MuiListItem-root {
        padding: 12px 20px;
        color: #4a4a4a;
        font-family: var(--FuturaStdMedium);

        /* justify-content: space-between; */
    }

    .headRt.respNav .MuiListItem-root > div {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .headRt.respNav .MuiListItem-root span.material-icons {
        padding-inline-end: 15px;
        color: #a6a6a6;
    }

    header .headRt ul li:not(:last-child) {
        margin-inline-end: 0;
    }

    .headRt.respNav .navRespLinks a {
        width: 100%;
        text-decoration: none;
        color: #4a4a4a;
        display: flex;
        align-items: flex-start;
        font-family: var(--FuturaStdMedium);
        flex-wrap: nowrap;
    }

    .liquidationTwo.emursMck .headRt.respNav .navRespLinks a {
        color: #a6a6a6;
    }

    .liquidationTwo.emursMck .headRt.respNav .navRespLinks a.active {
        color: var(--primColor);
    }

    .headRt.respNav .navRespLinks a .hnContact {
        padding-inline-start: 40px;
    }

    .headRt.respNav .navRespLinks a.active,
    .headRt.respNav .navRespLinks a.active span {
        color: var(--primColor);
    }

    .headRt.respNav .naLogoHead {
        padding: 20px 0px 20px 30px;
        padding-inline-start: 20px;
        padding-inline-end: 0;
    }

    .headRt.respNav .naLogoHead img {
        width: 100px;
        height: 100px;
        object-fit: contain;
    }

    .liquidationTwo .headerDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 300px;
        background: #fff;
        height: 100vh;
        overflow: hidden;
        /* overflow: -webkit-paged-x; */
    }

    .headerDrawer .mobShopLocation.MuiListItem-root {
        flex-wrap: wrap;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root p {
        font-size: 14px;
        padding-inline-start: 8px;
        margin: 0;
    }

    .headerDrawer .mobShopLocation.MuiListItem-root .MuiButtonBase-root {
        width: 100%;
        justify-content: space-between;
    }

    .headerDrawer .headerDrawerNavLinks {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: calc(100vh - 100px);
        height: auto;
    }

    .headerDrawer .headerDrawerNavLinks hr {
        width: 100%;
    }

    .navCategories {
        padding-inline-start: 0;
        margin: 0;
        padding-top: 10px;
    }

    .navCategories .navBack {
        color: #8a8a8a;
    }

    .navCategories .MuiListItem-button {
        width: 100%;
    }

    .notLoggedLink {
        padding: 10px 20px;
        width: 100%;
    }

    .headerSearchForm {
        width: 90%;
    }

    .mainHeader .headLt .headerLogo {
        width: 100%;
        height: 90px;
    }
}

@media (max-width: 991px) {
    .liquidationTwo.primeBids .nav-standard-top-area-search-input {
        height: 45px;
        padding-right: 0px;
    }

    .BidAuction .mainHeader .nav-standard-top-area-search-inner .MuiPaper-root {
        width: 206px;
        height: 28px;
    }
}

@media (max-height: 991px) {
    .liquidationTwo.primeBids .mainHeader .srchLstCntnr {
        padding-left: 0;
    }
}

@media (max-height: 600px) {
    .headRt.respNav .navRespLinks {
        height: 70vh;
        overflow: scroll;
        padding-bottom: 100px;
    }
}

@media (max-width: 768px) {
    .BidAuction .mainHeader .nav-standard-logo .headerLogo {
        max-width: 120px;
        height: 76px;
    }

    .BidAuction .mainHeader .headRt {
        margin-left: 0px;
        width: auto;
        display: none;
    }

    .headRt.respNav .navRespLinks {
        padding-left: 7px;
    }

    .BidAuction .mainHeader {
        flex-wrap: nowrap;
    }

    .BidAuction .mainHeader .mob-search-icon {
        display: block;
        padding: 20px;
        margin-right: 20px;
    }

    .BidAuction .mainHeader .header-search {
        margin-top: 0px !important;
    }

    .BidAuction .makeStyles-root-1 {
        width: auto;
    }

    .BidAuction .nav-standard-top-area-search-inner .makeStyles-root-2 {
        width: 206px !important;
    }

    .BidAuction .noAuth {
        height: 61px;
    }
}

@media (max-width: 992px) {
    .liquidationTwo.primeBids .mainHeader .nav-standard-top-area-search-inner {
        max-width: 100%;
    }

    .liquidationTwo.primeBids .lctnFltr {
        display: inline-flex;
    }

    .liquidationTwo.primeBids .headRt.headerrightSecMob {
        flex: 0 0 50%;
        max-width: 50%;
        text-align: right;
    }

    .liquidationTwo .headBottomNav li:not(:last-child) {
        margin-right: 15px;
    }

    .liquidationTwo .mainHeader .headLt .headerLogo {
        margin-right: 0px;
    }
}

@media (max-width: 990px) {
    /* .liquidationTwo .nav-standard-top-area-search-sumbit {
        width: 50px;
        height: 50px;
    } */
    .headRt.respNav .naLogoHead {
        padding: 15px 0px 15px 30px;
    }

    .liquidationTwo .headRt.respNav .naLogoHead img {
        height: 50px;
        width: 180px;
    }

    .liquidationTwo .headerDrawer .headRt.respNav .naLogoHead {
        padding: 20px 30px;
    }

    .liquidationTwo .headerDrawer .headDrawerClose {
        position: fixed;
        background: white;
        width: 30px;
        height: 30px;
        min-width: unset;
        border-radius: 100px;
    }

    .liquidationTwo .mainHeader .headRt ul li button .material-icons {
        padding: 0 8px;
    }

    .liquidationTwo .mnHdrLftDrwr .headDrawerClose {
        left: 319px;
    }

    .liquidationTwo .acntRtDrwr .headDrawerClose {
        right: 319px;
    }

    .liquidationTwo .headRt.respNav .navRespLinks a {
        font-size: 14px;
    }

    .liquidationTwo .headRt.respNav .MuiListItem-root span.material-icons {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .liquidationTwo .headerDrawer .headRt.respNav .naLogoHead {
        height: 80px;
        padding: 0px;
    }

    .BidAuction .mainHeader .hdrRtLt .headLogoWid {
        padding-left: 0px;
        padding-right: 0px;
    }

    .BidAuction .mainHeader .nav-standard-logo .headerLogo {
        width: 48px;
        height: 28px;
    }

    .liquidationTwo.primeBids .nav-standard-top-area-search-sumbit {
        top: 3px;
    }

    .liquidationTwo .mainHeader .headLt .headerLogo {
        width: fit-content;
        height: 60px;
        object-fit: contain;
    }

    .liquidationTwo .headRt.respNav .naLogoHead img {
        width: fit-content;
        height: 60px;
        object-fit: contain;
    }

    .liquidationTwo .mobHeader .headRt ul li button {
        width: auto;
        margin-left: 0px !important;
        padding: 0;
    }

    .mainHeader .headRt ul li:not(:last-child) {
        margin-right: 0;
    }

    .liquidationTwo .multisellerProductDrawer .mssBidIntDet .mssBidMisc {
        align-items: flex-start;
    }

    .liquidationTwo .respNav {
        height: 100vh;
        overflow: hidden;
    }

    .liquidationTwo .respNav .navRespLinks {
        height: calc(100vh - 100px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }

    .liquidationTwo .headLt .nav-standard-logo {
        height: 55px;
    }

    .liquidationTwo .lctnFltr .lctnIconVw {
        font-size: 20px;
    }

    .liquidationTwo .lctnFltr .MuiButton-label {
        font-size: 14px;
    }

    .liquidationTwo .mainHeader .srchLstCntnr {
        margin-left: 0px;
    }

    .liquidationTwo .nav-standard-top-area-search-input {
        height: 40px;
    }

    .liquidationTwo .nav-standard-top-area-search-sumbit {
        border-radius: 2px;
        height: 40px;
    }
}

@media (max-width: 425px) {
    .liquidationTwo .mainHeader .headLt .headerLogo {
        height: 45px;
        width: 100%;
        object-fit: contain;
    }

    .liquidationTwo .mainHeader .headLt .headLogoWid {
        height: 45px;
        width: 28px;
    }

    .liquidationTwo .headerDrawer .headRt.respNav .naLogoHead {
        height: 65px;
        padding: 0px;
    }

    .BidAuction .mainHeader .nav-standard-logo {
        width: 40px;
    }

    .liquidationTwo .headRt.respNav .naLogoHead img {
        width: 100%;
        height: 45px;
        object-fit: contain;
    }

    .liquidationTwo .grid-card .gcTimer {
        font-size: 12px;
    }

    .liquidationTwo .searchRt .grid-card .galleryPrice {
        font-size: 12px;
    }

    .liquidationTwo .Grid.liquidationBuyCard .primButton button .MuiButton-label {
        font-size: 11px;
    }

    .liquidationTwo .searchRt .grid-card .hostedBy {
        font-size: 12px;
    }

    .liquidationTwo .respNav {
        height: 100vh;
        overflow: hidden;
    }

    .liquidationTwo .respNav .navRespLinks {
        height: calc(100vh - 100px);
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        white-space: nowrap;
    }

    .headRt.respNav .navRespLinks {
        padding-bottom: 100px;
    }
}

@media (max-width: 600px) {
    .liquidationTwo .card.grid-card .galleryPrice > span:first-child {
        padding-bottom: 0;
        font-size: 13px;
        min-width: auto;
        white-space: break-spaces;
    }

    .liquidationTwo .card.grid-card .galleryPrice > span:last-child {
        font-size: 13px;
        white-space: nowrap;
    }

    .liquidationTwo .card.grid-card .galleryPrice .prcTxtVal {
        font-size: inherit;
    }

    .liquidationTwo .card.grid-card .galleryPrice.actnPriceVlue,
    .liquidationTwo .card.grid-card .galleryPrice {
        justify-content: flex-start;
    }

    .liquidationTwo .card.grid-card .name-txt {
        height: auto;
        /* max-height: 46px; */
        min-height: 46px;
    }

    /* 
    .liquidationTwo .card.grid-card .endDteTxt, */
    .liquidationTwo .pdtStsInfo {
        display: block;
    }

    .liquidationTwo .pdtStsInfo.listViewCdn {
        display: block;
    }

    .align-items-mob-start {
        align-items: flex-start !important;
    }

    .lctnDialogWrapper .termswrapper h4 {
        margin-top: 0px !important;
    }

    .lctnDialogWrapper .termswrapper input[type='checkbox'] {
        height: 25px;
        width: 25px;
    }

    .lctnDialogWrapper .rtInfoWrpr h4 {
        font-size: 16px;
    }

    .invcActnBtns {
        flex-wrap: wrap;
    }

    .liquidationTwo .headRt {
        padding-right: 6px;
        padding-left: 10px;
    }

    .liquidationTwo .headRt ul li.actnIcon button .material-icons {
        padding-right: 6px;
    }

    .nocardBanner {
        background: #4aa168;
        padding: 5px 12px;
    }

    .nocardBanner p {
        color: white;
        font-size: 15px;
        text-align: center;
        font-weight: 400;
        cursor: pointer;
    }

    .liquidationTwo .app-wrapper {
        padding-bottom: 50px;
    }
}

@media (max-width: 500px) {
    .liquidationTwo .losingcard .wngSts.LsgTxt {
        height: 16px;
        font-size: 12px;
        padding-bottom: 6px;
    }

    .liquidationTwo .winningcard .wngSts.grnTxt {
        height: 16px;
        font-size: 12px;
        padding-bottom: 6px;
    }

    .invoiceCard .card-header {
        flex-wrap: wrap;
    }

    .liquidationTwo .card.grid-card .name-txt {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .liquidationTwo .card.grid-card .galleryPrice > span:first-child {
        width: 100% !important;
    }

    .liquidationTwo .card.grid-card .galleryPrice .prcTxtVal {
        padding-left: 0;
    }

    .liquidationTwo .card.grid-card .name-txt {
        max-height: initial;
        min-height: 1ex;
        -webkit-line-clamp: 1;
    }

    .liquidationTwo .card.grid-card .galleryPrice > span:first-child {
        width: auto;
    }

    .liquidationTwo .grid-card .gcTimer .timerCnt span + span {
        display: none;
    }

    .liquidationTwo .card.grid-card .galleryPrice.actnPriceVlue,
    .liquidationTwo .card.grid-card .galleryPrice {
        flex-wrap: nowrap;
    }

    .searching-inventory {
        width: 80px !important;
    }

    .liquidationTwo .home .siteBanner .bannerContent h2 {
        font-size: 13px !important;
    }

    .liquidationTwo .siteBanner .bannerContent button {
        height: 35px;
    }

    .liquidationTwo .pdtStsInfo {
        grid-template-columns: 1fr;
    }

    .liquidationTwo .galleryPrice.condition {
        min-height: 5ex;
    }

    .liquidationTwo .nav-standard-top-area-search-input {
        padding-right: 45px;
    }
}

@media (max-width: 375px) {
    .BidAuction .mainHeader .headRt {
        margin-left: -45px;
    }

    .BidAuction .mainHeader .headRt .MuiButton-label {
        font-size: 12px;
    }
}

@media (max-width: 350px) {
    .BidAuction .mainHeader .header-search {
        width: 160px;
    }
}
